import React from 'react'; // eslint-disable-line import/no-extraneous-dependencies
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import reactStringReplace from 'react-string-replace';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import Page from '../../components/Page';
import Location from '../../components/Location';
import ShortAbout from '../../components/ShortAbout';

import styles from './career.module.css';

function formatBlockText(txt) {
  return reactStringReplace(txt, /LINK:(\/[^\s\.]+)/, (link) => {
    return <Link key={link} to={link}>{link.replace('/', '').replace('-', ' ')}</Link>;
  });
}

const CareerPage = ({ data, params }) => {
  const { uri } = params;
  const opening = data.allOpeningsJson.edges
    .map((x) => x.node)
    .find((x) => x.uri === uri);

  return (
    <div>
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          {
            name: 'description',
            content: 'To learn what we don’t know. To solve hard challenges. To enjoy the journey.',
          },
          {
            name: 'keywords',
            content:
              'javascript, js, consulting, big data, hadoop, spark, machine learning, tensor flow, react, angular, web, html, css, design, ux',
          },
        ]}
      >
        <html lang="sv" />
        <link rel="icon" href="/favicon.ico?v=2" />
      </Helmet>
      <div className={styles.content}>
        <Header className={styles.headerBackground} />
        <Page bgColor="#F8F6FA">
          <section>
            <h1 className={styles.title}>
              {opening.tags.map((tag, i) => (
                <React.Fragment key={i}>
                  {i != 0 ? ' · ' : ''}
                  {tag}
                </React.Fragment>
              ))}
            </h1>
            <h2>{opening.title}</h2>
            <p>{opening.description}</p>
            <Link to="#apply">
              <button>Apply now</button>
            </Link>
          </section>
          <main>
            {opening.blocks.map((block, i) => (
              <section key={i} className={styles.section}>
                <div className={styles.sectionText}>
                  <h3>{block.title}</h3>
                  <p>{formatBlockText(block.text)}</p>
                </div>
              </section>
            ))}
            <section className={styles.info}>
              <div>
                <p>
                  <span className={styles.infoIcon}><img src="/images/schedule_24dp_p.svg" /></span>
                  <span className={styles.infoTitle}>Time</span>
                  <span className={styles.infoText}>{opening.time}</span>
                </p>
              </div>
              <div>
                <p>
                  <span className={styles.infoIcon}><img src="/images/location_city_p.svg" /></span>
                  <span className={styles.infoTitle}>Location</span>
                  <span className={styles.infoText}>{opening.location}</span>
                </p>
              </div>
              <div>
                <p>
                  <span className={styles.infoIcon}><img src="/images/flag_24dp_p.svg" /></span>
                  <span className={styles.infoTitle}>Start</span>
                  <span className={styles.infoText}>{opening.start}</span>
                </p>
              </div>
              <Link to="#apply">
                <button>Apply now >></button>
              </Link>
            </section>
            <section id="apply" className={styles.contact}>
              <h3>Your contact</h3>
              <img src={opening.contact.img} />
              <p className={styles.contactName}>{opening.contact.name}</p>
              <p className={styles.contactTitle}>{opening.contact.title}</p>
              <p className={styles.contactMsg}>Drop me a message!</p>
              <a
                href="mailto:career@northlink.io"
                className={styles.contactMsg}
              >
                career@northlink.io
              </a>
            </section>
            <ShortAbout numEmployees={data.allTeamJson.edges.length} />
          </main>
        </Page>
        <Location title={data.site.siteMetadata.title} id="location" />
        <Footer
          products={data.allProductsJson.edges.map((x) => x.node)}
          cases={data.allCasesJson.edges.map((x) => x.node)}
          title={data.site.siteMetadata.title}
        />
      </div>
    </div>
  );
};

export default CareerPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allOpeningsJson {
      edges {
        node {
          title
          uri
          image
          description
          tags
          location
          time
          start
          contact {
            name
            img
            title
          }
          blocks {
            title
            text
          }
        }
      }
    }
    allTeamJson {
      edges {
        node {
          name
        }
      }
    }
    allProductsJson {
      edges {
        node {
          url
          shortTitle
        }
      }
    }
    allCasesJson {
      edges {
        node {
          url
          shortTitle
        }
      }
    }
  }
`;
